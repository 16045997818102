<template>
  <module
    ref="module"
    id="billingMarketplaceModule"
    titleIcon="fa fa-retweet"
    api-url="MAINAPIURLV4"
    :title="$t('payments')"
    :filters.sync="filters"
    :url="`/${userIdUrl}/billing/marketplace/legacy`"
    :export-data-with-checkbox="false"
    :use-exports="true"
    :export-header="exportHeader"
    :export-data="exportData"
    @before-export="prepareExportData"
  >
    <template slot="toolbar-global">
      <div class="mr-1" v-if="isAdmin">
        <fg-input
          id="userIdSearch"
          type="email"
          v-model="useridFilter"
          addon-right="fa fa-step-forward"
          class="input-toolbar"
          :placeholder="`${$t('userid')}`"
          @change="filterByUser"
        >
          <button
            slot="addonRight"
            class="btn btn-xs btn-primary"
            @click="filterByUser"
          >
            <i class="fa fa-step-forward"></i>
          </button>
        </fg-input>
      </div>
    </template>
    <template slot="global">
      <modal
        id="billingMarketplaceDisputeModal"
       :required="true"
       :show.sync="showDisputeModal"
       headerClasses="justify-content-center"
       modalClasses="modal-md"
      >
        <h4 slot="header" class="title title-up">
          {{ $t('disputetitle') }}
        </h4>
        <label>{{ $t('disputedescription') }}:</label>
        <br>
        <textarea class="form-control" rows="10" v-model="disputeDescription"></textarea>
        <template slot="footer">
          <div class="left-side">
            <p-button type="success" link @click="dispute">{{ $t('confirm') }}</p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="warning" link @click="closeDisputeModal">{{ $t('cancel') }}</p-button>
          </div>
        </template>
      </modal>
      <modal
        id="billingMarketplaceInfoModal"
       :required="true"
       :show.sync="showModal"
       headerClasses="justify-content-center"
       modalClasses="modal-md"
      >
        <h4 slot="header" class="title title-up">
          {{ $t('billinginstructions') }}
          <br>
          <br>
          <badge :type="selectedRowData.userid_payer === userid ? 'danger' : 'success'" pill>
            {{ getUseridType(selectedRowData) }}
          </badge>
        </h4>
        <div :class="`row item-list ${isRecharge(selectedRowData) ? 'recharging' : ''}`">
          <span class="label col-6">{{ $t('payer') }} {{ $t('fullname') }}</span>
          <span :class="`value col-6`" >
            {{ selectedRowData.payer_fullname }}
          </span>
          <hr>
          <span class="label col-6">{{ $t('product') }}</span>
          <span :class="`value col-6`" >
            {{ selectedRowData.product }}
          </span>
          <hr>
          <span class="label col-6">{{ $t('profit') }}</span>
          <span :class="`value col-6`" >
            {{ getCurrencySymbol(selectedRowData.account_currency) }} {{ selectedRowData.billable_profit }}
          </span>
          <hr>
          <span class="label col-6" >
            {{ $t('adjusttxt') }} {{ $t('performancefee') }}
          </span>
          <span :class="`value col-6`" >
            <fg-numeric
              percentage
              input-classes="xs"
              v-model="new_performance_fee"
              :min="0"
              :max="maxPerformanceFee"
              :step="1"
              :disabled="!canUpdate(selectedRowData)"
              @change="recalc"
            ></fg-numeric>
          </span>
          <hr>
          <span class="label col-6">{{ $t('amount') }}</span>
          <span :class="`value col-6 ${isPayer(selectedRowData) ? 'text-danger' : ''}`" >
            $ {{ selectedRowData.performance_fee_usd }} ({{ selectedRowData.performance_fee_percentage }}%)
            <span class="recalc"> {{ recalcRow.performance_fee_usd }} </span>
          </span>
          <hr>
          <span class="label col-6">{{ $t('hokotxt') }} {{ $t('parttxt') }} (%)</span>
          <span :class="`value col-6`">
            $ {{ parseFloat(selectedRowData.hoko_part).toFixed(2) }} ({{ selectedRowData.hoko_part_percentage }}%)
            <span class="recalc"> {{ recalcRow.hoko_part }} </span>
          </span>
          <hr>
          <span class="label col-6">{{ $t('tradertxt') }} {{ $t('parttxt') }} (%)</span>
          <span :class="`value col-6 ${isTrader(selectedRowData) ? 'text-success' : ''}`">
            $ {{ parseFloat(selectedRowData.trader_part).toFixed(2) }} ({{ selectedRowData.trader_percentage }}%)
            <span class="recalc"> {{ recalcRow.trader_part }} </span>
          </span>
          <hr>
          <span class="label col-6">{{ $t('affiliatetxt') }} {{ $t('parttxt') }} (%)</span>
          <span :class="`value col-6 ${isAffiliate(selectedRowData) ? 'text-success' : ''}`">
            $ {{ affiliatePart(selectedRowData) }}
            ({{ affiliatePerc(selectedRowData) }}%)
            <span class="recalc"> {{ recalcRow.affiliate_final }} </span>
          </span>
          <hr>
          <span class="col-12 my-2">
            <b>{{ $t('commenttxt') }}</b>:
          </span>
          <div class="textarea">{{ selectedRowData.comment }}</div>
          <hr>
          <span class="col-12 col-centered mt-2" v-if="isApproved(selectedRowData)">
            <badge type="info" pill>{{ $t('approved_already') }}</badge>
          </span>
          <span class="col-12 col-centered mt-2" v-else>
            <p-checkbox v-if="!isAffiliate(selectedRowData)" v-model="approve">{{ $t('approve') }} ?</p-checkbox>
          </span>
        </div>
        <template slot="footer" v-if="(isAffiliate(selectedRowData) || isApproved(selectedRowData)) && !isRecharge(selectedRowData)">
          <p-button link type="warning" @click="closeSelectedRowModal" >{{ $t('confirm') }}</p-button>
        </template>
        <template slot="footer" v-else>
          <div class="left-side" v-if="isRecharge(selectedRowData)">
            <p-button
              link
              type="success"
              :loading="processing"
              @click="recalcSelectedRowModal"
            >
              {{ $t('upentrybtn') }} {{ $t('performancefee') }}
            </p-button>
          </div>
          <div class="left-side" v-else>
            <p-button
              link
              type="success"
              :loading="processing"
              :disabled="canApprove"
              @click="approveSelectedRowModal"
            >
              {{ $t('approve') }}
            </p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="danger" link @click="closeSelectedRowModal">
              {{ $t('cancel') }}
            </p-button>
          </div>
        </template>
      </modal>
      <modal
        required
        :show.sync="waitingModal"
        headerClasses="justify-content-center"
        modalClasses="modal-md"
        >
        <h4 slot="header" class="title title-up">{{ $t('waiving_charge') }} </h4>
        <div class="text-center">
          <div>
            <h2>{{ $t('pleasewait') }} </h2>
          </div>
          <div>
            <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
          </div>
        </div>

      </modal>
    </template>
    <template slot="table-columns">
      <el-table-column :label="$t('infotxt')" width="110px" align="center">
        <template slot-scope="{row}">
          <p-button
            size="sm"
            type="primary"
            class="py-1 px-3"
            round
            outline
            :title="$t('billinginstructions')"
            @click="openSelectedRowModal(row)"
          >
            <i class="fa fa-info"></i>
          </p-button>
        </template>
      </el-table-column>
      <el-table-column prop="dispute" :label="$t('action')" width="90px" align="center" >
        <template slot-scope="{row}">
          <div class="action-button">
            <p-button
              size="sm"
              v-if="!canUpdate(row)"
              :title="$t('chargedtxt')"
              disabled
            >
              <i class="fa fa-usd"></i>
            </p-button>
            <p-button
              size="sm"
              type="danger"
              v-if="isPayer(row) && !row.dispute && canUpdate(row)"
              :title="$t('disputetitle')"
              @click="openDisputeModal(row)"
            >
              <i class="fa fa-commenting"></i>
            </p-button>
            <p-button
              size="sm"
              v-if="isPayer(row) && row.dispute && canUpdate(row)"
              :title="$t('disputedalready')"
              @click="onDisputed"
            >
              <i class="fa fa-comments-o"></i>
            </p-button>
            <p-button
              size="sm"
              type="info"
              v-if="isTrader(row) && canUpdate(row)"
              :title="$t('waivetitle')"
              @click="waive(row)"
            >
              <i class="fa fa-handshake-o"></i>
            </p-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="date_reference" :label="$t('date')" width="120px" align="center" sortable />
      <el-table-column :label="$t('type')" width="120px"  align="center" >
        <template slot-scope="{row}">
          <badge
            :class="row.billed ? 'charged' : ''"
            :type="row.userid_payer === userid ? 'danger' : 'success'"
            pill
          >
            {{ getUseridType(row) }}
          </badge>
        </template>
      </el-table-column>
      <el-table-column prop="payer_fullname" :label="`${$t('payer')} ${$t('fullname')}`" width="220px" show-overflow-tooltip />
      <el-table-column prop="account_currency" :label="$t('currency')" width="120px" align="center" />
      <el-table-column prop="currency_exchange" :label="$t('exchange')" width="120px" align="center" >
        <template slot-scope="{row}">
          <span>
           <b>{{ parseFloat(row.currency_exchange).toFixed(2) }}</b>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('amount')" width="120px" align="center" >
        <template slot-scope="{row}">
          <span :class="getColorByOperationType(row)">
           <b v-if="isPayer(row)">$ {{ parseFloat(row.performance_fee_usd).toFixed(2) }}</b>
           <b v-if="isTrader(row)">$ {{ parseFloat(row.trader_part).toFixed(2) }}</b>
           <b v-if="isAffiliate(row)">$ {{ parseFloat(row.affiliate_part).toFixed(2) }}</b>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('percent_from_total')" width="120px" align="center" >
        <template slot-scope="{row}">
          <span>
           <b v-if="isPayer(row)">{{ Math.round(getPercentage(row.performance_fee_usd, row.performance_fee_usd)) }}%</b>
           <b v-if="isTrader(row)">{{ row.trader_percentage }}%</b>
           <b v-if="isAffiliate(row)">{{ row.affiliate_percentage }}%</b>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="product" :label="$t('product')" width="180px" sortable show-overflow-tooltip />
      <el-table-column prop="comment" :label="$t('commenttxt')" width="350px" show-overflow-tooltip />
      <el-table-column v-if="isAdmin" prop="id" :label="$t('id')" width="90px" show-overflow-tooltip />
    </template>
    <template slot="filter-field">
      <div class="form-group">
        <label>{{ $t('billed') }}</label>
        <el-select v-model="filters.billed" clearable>
          <el-option value="" :label="$t('bothtxt')"></el-option>
          <el-option value="pending" :label="$t('pendingtxt')"></el-option>
          <el-option value="charged" :label="$t('chargedtxt')"></el-option>
        </el-select>
      </div>
      <fg-input v-if="isAdmin" v-model="filters.id" :label="$t('id')"></fg-input>
      <fg-input v-model="filters.userid_provider" :label="$t('tradertxt')"></fg-input>
      <fg-input v-model="filters.userid_payer" :label="$t('payer')"></fg-input>
    </template>
  </module>
</template>

<script>
import Constants from 'src/assets/constants';
import { Badge, Modal } from 'src/components/UIComponents';
import swal from 'sweetalert2';
import { getCurrency } from 'src/util/CurrencyUtils';

const rules = Constants.methods.getRules();
const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
  name: "LedgerMarketplace",
  components: {
    Badge,
    Modal,
  },
  computed: {
    userIdUrl() {
      return this.userid || 'a';
    },
    isAdmin() {
      return rules.isAdmin;
    },
    canApprove() {
      return !this.approve;
    },
    maxPerformanceFee() {
      return this.selectedRowData && this.selectedRowData.strategy_max_price ? this.selectedRowData.strategy_max_price : 0;
    },
  },
  data() {
    return {
      userid: user_data.email,
      useridFilter: user_data.email,
      filters: {
        billed: 'pending',
      },
      processing: false,
      showModal: false,
      showDisputeModal: false,
      waitingModal: false,
      approve: false,
      selectedRowData: {},
      recalcRow: {},
      stratInfo: {},
      new_performance_fee: 0,
      exportHeader: [],
      exportData: [],
      disputeDescription: '',
    }
  },
  methods: {
    currentProfitChargeable(performance_type) {
      return performance_type === 'Balance' ?
        this.selectedRowData.balance_profit_chargeable :
        this.selectedRowData.equity_profit_chargeable;
    },
    getCurrencySymbol(code) {
      return getCurrency(code);
    },
    recalc() {
      if(!this.isRecharge(this.selectedRowData)) {
        this.recalcRow = {};
        return;
      }
      if(!this.stratInfo) {
        return;
      }
      const data = {...this.selectedRowData};
      const rec = {};
      rec.performance_fee = this. currentProfitChargeable(this.stratInfo.performance_type) * (this.new_performance_fee / 100);
      rec.performance_fee_usd = parseFloat(rec.performance_fee / data.currency_exchange).toFixed(2);
      rec.hoko_part = parseFloat(rec.performance_fee_usd * this.stratInfo.hoko_performance_fee).toFixed(2);
      rec.hoko_part_percentage = this.getPercentage(rec.hoko_part, rec.performance_fee_usd);
      rec.whitelabel_final_fee = data.whitelabel_performance_fee ? data.whitelabel_performance_fee > (data.affiliate_performance_fee || 0) ? data.whitelabel_performance_fee - (data.affiliate_performance_fee || 0) : data.whitelabel_performance_fee : 0;
      rec.whitelabel_part = data.userid_whitelabel ? parseFloat(rec.performance_fee_usd * rec.whitelabel_final_fee) : 0;
      rec.whitelabel_percentage = data.userid_whitelabel ? this.getPercentage(rec.whitelabel_part, rec.performance_fee_usd) : 0;
      rec.affiliate_part = data.userid_affiliate ? parseFloat(this.$toLowerEquals(data.userid_affiliate, data.userid_provider) ? 0 : rec.performance_fee_usd * data.affiliate_performance_fee) : 0;
      rec.affiliate_percentage = data.userid_affiliate ? this.getPercentage(rec.affiliate_part, rec.performance_fee_usd) : 0;
      rec.affiliate_final = (rec.whitelabel_part + rec.affiliate_part).toFixed(2);
      rec.trader_percentage = 1 - (rec.hoko_part_percentage / 100) - (rec.whitelabel_percentage / 100) - (rec.affiliate_percentage / 100);
      rec.trader_part = parseFloat(rec.performance_fee_usd * rec.trader_percentage).toFixed(2);
      this.recalcRow = {...rec};
    },
    affiliatePart(row) {
      return (parseFloat(row.affiliate_part) + parseFloat(row.wl_affiliate_part)).toFixed(2);
    },
    affiliatePerc(row) {
      return row.affiliate_percentage + row.wl_affiliate_percentage;
    },
    isAffiliate(row) {
      return (this.$toLowerEquals(row.userid_affiliate, this.userid) || this.$toLowerEquals(row.userid_whitelabel, this.userid)) && !this.isTrader(row);
    },
    isTrader(row) {
      return this.$toLowerEquals(row.userid_provider, this.userid);
    },
    isPayer(row) {
      return this.$toLowerEquals(row.userid_payer, this.userid);
    },
    isApproved(row) {
      return (this.$toLowerEquals(row.userid_payer, this.userid) && row.payer_confirmation) ||
        (this.$toLowerEquals(row.userid_provider, this.userid) && row.provider_confirmation);
    },
    canUpdate(row) {
      return this.isTrader(row) && !row.billed;
    },
    isRecharge(row) {
      if(!this.canUpdate(row)) {
        return false;
      }
      return row.strategy_price && row.strategy_price !== this.new_performance_fee;
    },
    getUseridType(row) {
      let type = this.$toLowerEquals(row.userid_payer, this.userid) ? this.$t('paying') : this.$t('receiving');
      if(row.billed) {
        if(type === this.$t('paying')) {
          type = this.$t('paid')
        }
        if(type === this.$t('receiving')) {
          type = this.$t('received')
        }
      }
      if(row.broker_internal_billing_id) { // if charge have an internal billing its status overcome
        if(!row.broker_internal_billing_status) {
          type = this.$t('awaiting_broker');
        }
        if(row.broker_internal_billing_status == 0) {
          type = this.$toLowerEquals(row.userid_payer, this.userid) ?
            this.$t('paying') :
            this.$t('receiving');
        }
        if(row.broker_internal_billing_status == 1) {
          type = this.$toLowerEquals(row.userid_payer, this.userid) ?
            this.$t('broker_debited') :
            this.$t('received');
        }
        if(row.broker_internal_billing_status == 2) {
          type = this.$toLowerEquals(row.userid_payer, this.userid) ?
            this.$t('broker_debited') :
            this.$t('broker_credited');
        }
      }
      return type;
    },
    getPercentage(part, amount) {
      return parseFloat((part * 100) / amount).toFixed(2);
    },
    getColorByOperationType(row) {
      return this.$toLowerEquals(row.userid_payer, this.userid) ? 'text-danger' : 'text-success'
    },
    async openSelectedRowModal(rowData) {
      this.selectedRowData = rowData;
      this.recalcRow = {};
      await this.getStratInfo();
      this.new_performance_fee = rowData.strategy_price || 0;
      this.showModal = true;
    },
    closeSelectedRowModal() {
      this.selectedRowData = {};
      this.showModal = false;
      this.approve = false;
      this.filterByUser();
    },
    openDisputeModal(rowData) {
      this.selectedRowData = rowData;
      this.showDisputeModal = true;
    },
    closeDisputeModal() {
      this.selectedRowData = {};
      this.disputeDescription = '';
      this.showDisputeModal = false;
      this.filterByUser();
    },
    onDisputed() {
      swal({
        text: this.$t('disputedalready'),
        type: 'info',
      })
    },
    async getStratInfo() {
      this.processing = true;
      const response = await this.$getStratInfoLedgerProductMarketplace({
        ...this.selectedRowData,
        userid: this.userid,
      }).catch(err => this.processing = false);
      this.processing = false;
      if(this.validateResponse(response)) {
        this.stratInfo = response.data;
      } else {
        this.stratInfo = {};
      }
    },
    async approveSelectedRowModal() {
      this.processing = true;
      const response = await this.$approveLedgerProductMarketplace({
        ...this.selectedRowData,
        userid: this.userid,
        confirmation_type: this.getUseridType(this.selectedRowData),
      }).catch(err => this.processing = false);
      this.processing = false;
      if(this.validateResponse(response)) {
        this.closeSelectedRowModal();
      }
    },
    async recalcSelectedRowModal() {
      this.processing = true;
      const response = await this.$updateLedgerProductMarketplace({
        ...this.selectedRowData,
        userid: this.userid,
        new_performance_fee: this.new_performance_fee,
      }).catch(err => this.processing = false);
      this.processing = false;
      if(this.validateResponse(response)) {
        this.closeSelectedRowModal();
      }
    },
    async dispute(row) {
      if(!this.disputeDescription) {
        this.$toast.warning('disputedescription');
        return;
      }
      swal({
        title: this.$t('needconfirmation'),
        text: this.$t('confirmdispute'),
        type: 'warning',
        showCancelButton: true,
      }).then(async () => {
          const response = await this.$disputeLedgerProductMarketplace({
            ...this.selectedRowData,
            userid: this.userid,
            details: this.disputeDescription,
          });
          if(this.validateResponse(response)) {
            this.closeDisputeModal();
          }
      }).catch(swal.noop);
    },
    async waive(row) {
      swal({
        title: this.$t('needconfirmation'),
        text: this.$t('confirmwaive'),
        type: 'warning',
        showCancelButton: true,
      }).then(async () => {
          this.waitingModal = true
          const response = await this.$waiveLedgerProductMarketplace({
            ...row,
            userid: this.userid,
          });
          if(this.validateResponse(response)) {
            this.filterByUser();
          }
      }).catch(swal.noop);
    },
    validateResponse(response) {
      this.waitingModal = false
      if(!response.success) {
        if(response.msg) {
          this.$toast.warning(this.$t(response.msg));
        } else {
          this.$toast.error(this.$t('unkownerror'));
        }
        return false;
      }
      this.$toast.success(this.$t(response.msg));
      return true;
    },
    clearList() {
      this.filters = {
        billed: 'pending',
      }
      this.useridFilter = user_data.email;
      this.filterByUser();
    },
    filterByUser() {
      this.userid = this.useridFilter;
      this.$refs.module.search();
    },
    prepareExportData(resultList) {
      this.exportHeader = [
        `${this.$t('date')}`,
        `${this.$t('type')}`,
        `${this.$t('payer')} ${this.$t('fullname')}`,
        `${this.$t('currency')}`,
        `${this.$t('amount')}`,
        `${this.$t('hokotxt')} ${this.$t('parttxt')}`,
        `${this.$t('hokotxt')} %`,
        `${this.$t('tradertxt')} ${this.$t('parttxt')}`,
        `${this.$t('tradertxt')} %`,
        `${this.$t('affiliatetxt')} ${this.$t('parttxt')}`,
        `${this.$t('affiliatetxt')} %`,
        `${this.$t('product')}`,
        `${this.$t('commenttxt')}`,
      ];
      this.exportData = resultList.map(obj => {
        return [
          obj.date_reference,
          this.getUseridType(obj),
          obj.payer_fullname,
          obj.account_currency,
          obj.performance_fee_usd,
          obj.hoko_part,
          obj.hoko_part_percentage,
          obj.trader_part,
          obj.trader_percentage,
          obj.affiliate_part,
          obj.affiliate_percentage,
          obj.product,
          `"${obj.comment}"`,
        ];
      });
    },
  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
#billingMarketplaceDisputeModal {
  textarea.form-control {
    padding: 1rem;
    line-height: 1.5rem;
    max-height: 20rem;
  }
}
.p-badge {
  width: 100%;
  display: inline-block;
  line-height: $font-size-small !important;
  font-size: $font-size-mini !important;
  margin-bottom: 0 !important;
  &.charged {
    &.badge-success {
      color: $success-states-color;
      background-color: $white-bg;
      border-color: $success-states-color;
    }

    &.badge-danger {
      color: $danger-states-color;
      background-color: $white-bg;
      border-color: $danger-states-color;
    }
  }
}
.action-button {
  button {
    font-size: small;
    min-width: 3rem;
    &.btn.btn-default {
      font-size: smaller;
    }
  }
}
.textarea {
  width: 100%;
  padding-left: 1rem;
  margin-top: 0.25rem;
  max-height: 8rem;
  overflow: auto;
}
.col-centered {
  float: none;
  margin: 0 auto;
  text-align: center;
}
.item-list::v-deep {
  margin-left: -1.5rem;
  &.recharging {
    .value {
      .recalc {
        font-size: smaller;
        color: $danger-color;
        &:before {
          content: '\21D2';
          margin-left: 0.25rem;
        }
      }
    }
  }
  .label {
    font-weight: bold;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .value {
    font-weight: bold;
    text-align: right;
    .fg-numeric {
      position: relative;
      display: inline-block;
      right: -0.25rem;
      height: 0;
      width: 9rem;
      input {
        height: 1.5rem;
      }
      .el-input-number__decrease,
      .el-input-number__increase {
        i {
          transform: translateY(15%) !important;
        }
      }
    }
  }
  hr {
    width: 100%;
    margin-top: 0.85rem;
    border-left: $border $light-gray;
  }
}
@media screen and (max-width: 768px) {
  .item-list::v-deep {
    font-size: small;
    .value {
      .fg-numeric {
        input {
          height: 0.75rem !important;
        }
        .el-input-number__decrease,
        .el-input-number__increase {
          i {
            transform: translateY(0%) !important;
          }
        }
      }
    }
  }
}
</style>
